const preventDefaultFunction: (event: any) => void = event => {
  try {
    event?.preventDefault();
    event?.stopPropagation();
  } catch (error) {
    console.error(error);
  }
};

const getIsValidString: (string: string) => boolean = string => {
  try {
    const regex: RegExp = /^[\p{L}\d\s_&.,!@£$₣+%():;"'-]{1,30}$/u;

    if (!regex.test(string)) return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const closeErrorContainer: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const errorContainer: any = document.getElementById('error-container');

    errorContainer.classList.remove('active');
  } catch (error) {
    console.error(error);
  }
};

const handleShowConditionalInputByValue: (event: any, inputId: string, value: string) => void = (
  event,
  inputId,
  value
) => {
  const targetInputContainer = document.getElementById(inputId);
  console.log(targetInputContainer);

  if (!targetInputContainer) return;

  const targetInput = targetInputContainer.getElementsByTagName('INPUT')[0];

  if (!targetInput) return;

  if (event.currentTarget.dataset.value === value) {
    targetInputContainer.style.display = 'block';
    targetInputContainer.classList.add('required');
    targetInput.setAttribute('required', 'true');
  } else {
    targetInputContainer.style.display = 'none';
    targetInputContainer.classList.remove('required');
    targetInput.removeAttribute('required');
  }
};
